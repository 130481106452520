import React from 'react';
import {
   Dialog,
   DialogTitle,
   DialogContent,
   Stack,
   TextField,
   Button,
   FormControl,
   InputLabel,
   Select,
   MenuItem,
   DialogActions,
   CircularProgress,
   Box,
   Link,
   FormControlLabel,
   Checkbox,
   Collapse,
} from '@mui/material';
import $ from 'jquery';
import { useCookies } from 'react-cookie';
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import { Helmet } from 'react-helmet'

export function Login(props) {
   const [cookies, setCookie/*, removeCookie*/] = useCookies();
   const [config, setConfig] = React.useState(null);
   const [failure, setFailure] = React.useState(null);
   const [systemID, setSystemID] = React.useState(null);
   const [login, setLogin] = React.useState(null);
   const [password, setPassword] = React.useState(null);

   React.useEffect(() => {
      $.ajax({
         url: '/config',
         type: 'get',
         dataType: 'json',
      }).done(function(ajax) {
         setConfig(ajax);
      }).fail(function(jqXHR) {
         setFailure({ message: 'ÐžÑˆÐ¸Ð±ÐºÐ° Ð¿Ð¾Ð»ÑƒÑ‡ÐµÐ½Ð¸Ñ ÑÐ¿Ð¸ÑÐºÐ° ÑÐ¸ÑÑ‚ÐµÐ¼.', ...jqXHR.responseText ? { error: jqXHR.responseText } : {}});
      });
   }, []);

   React.useEffect(() => {
      if( !config?.systems?.length )
         return;
      
      var id = config.systems[0].id;

      if( config.systemCookie && cookies[config.systemCookie] ) {
         for(const system of config.systems) {
            if( system.id === cookies[config.systemCookie] ) {
               id = system.id;
               break;
            }
         }
      }
      
      setSystemID(id);
   }, [config]); // eslint-disable-line

   const system = React.useMemo(() => {
      const system = config?.systems?.filter(system => system.id === systemID)[0];

      if( system?.userCookie ) {
         setLogin(cookies[system.userCookie]);
      }

      return system;
   }, [config?.systems, systemID, cookies]);
   
   React.useEffect(() => {
      if( !config?.systemCookie || cookies[config.systemCookie] === systemID)
         return;
      
      setCookie(config?.systemCookie, systemID);
   }, [systemID, config?.systemCookie, cookies, setCookie]);

   const handleLogin = React.useCallback(function(event) {
      $.ajax({
         url: '/login',
         type: 'post',
         data: {
            'system': systemID,
            'login': login,
            'password': password,
         },
         dataType: 'json',
         withCredentials: true,
      }).done(function(data, textStatus, jqXHR) {
         if( data.ok ) {
            if( data.redirect )
               document.location.href = data.redirect;
         } else {
            setFailure({
               message: 'Ð’Ñ…Ð¾Ð´ Ð½Ðµ ÑƒÐ´Ð°Ð»ÑÑ.',
               error: 'ÐŸÐ¾Ð¶Ð°Ð»ÑƒÐ¹ÑÑ‚Ð°, Ð¿Ñ€Ð¾Ð²ÐµÑ€ÑŒÑ‚Ðµ Ð´Ð°Ð½Ð½Ñ‹Ðµ ÑÐ²Ð¾ÐµÐ¹ ÑƒÑ‡ÐµÑ‚Ð½Ð¾Ð¹ Ð·Ð°Ð¿Ð¸ÑÐ¸.',
               buttons: [{
                  label: 'Ð—Ð°ÐºÑ€Ñ‹Ñ‚ÑŒ',
                  onClick: event => setFailure(null),
               }],
            });
         }
      }).fail(function(jqXHR) {
         setFailure({ message: 'ÐžÑˆÐ¸Ð±ÐºÐ° Ð°ÑƒÑ‚ÐµÐ½Ñ‚Ð¸Ñ„Ð¸ÐºÐ°Ñ†Ð¸Ð¸.', ...jqXHR.responseText ? { error: jqXHR.responseText } : {}});
      });
   }, [systemID, login, password]);

   const handleChangeSystem = React.useCallback(function(event) {
      if( event.target.value !== systemID) {
         setSystemID(event.target.value, config?.systems);
      }
   }, [config?.systems, systemID]);

   const handleChangeLogin = React.useCallback(function(event) {
      setLogin(event.target.value);
   }, []);

   const handleChangePassword = React.useCallback(function(event) {
      setPassword(event.target.value);
   }, []);

   const handleKeyDown = React.useCallback(function(event) {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
         handleLogin(event);
         event.preventDefault();
      }
   }, [handleLogin]);
      
   return (
      <>
      {system?.css && (
         <Helmet>
            <link href={system.css} rel="stylesheet"/>
         </Helmet>
      )}
      {system?.banners?.map((banner, index) => banner.href ? (
         <Link
            key={index}
            className={`banner${banner.class ? ` ${banner.class}` : ''}`}
            href={banner.href}
            target={banner.target}
         >
            {banner.label}
         </Link>
      ) : (
         <Box
            key={index}
            className={`banner${banner.class ? ` ${banner.class}` : ''}`}
         >
            {banner.label}
         </Box>
      ))}
      {failure ? (
         <Dialog
            open={true}
            className={"failure"}
         >
            <DialogTitle>{failure.message}</DialogTitle>
            <DialogContent>
            {failure?.error && (
               <Box>
                  <FormControlLabel
                     control={<Checkbox checked={failure.detail === true}/>}
                     label="Ð”ÐµÑ‚Ð°Ð»Ð¸Ð·Ð°Ñ†Ð¸Ñ Ð¾ÑˆÐ¸Ð±ÐºÐ¸"
                     onClick={event => setFailure({...failure, detail: event.target.checked})}
                  />
                  <Collapse
                     in={failure.detail === true}
                     timeout={1000}
                  >
                     <Box sx={{ whiteSpace: 'pre' }}>
                        {parse(DOMPurify.sanitize(failure.error, {
                           USE_PROFILES: { html: true },
                        }))}
                     </Box>
                  </Collapse>
               </Box>
            )}
            {failure?.buttons && (
               <DialogActions>
               {failure.buttons.map((button, index) => (
                  <Button
                     key={index}
                     {...button}
                  >
                     {button.label}
                  </Button>
               ))}
               </DialogActions>
            )}
            </DialogContent>
         </Dialog>
      ) : (
         <Dialog
            open={true}
            className="login"
            BackdropProps={{ invisible: true }}
         >
            <DialogTitle>Login</DialogTitle>
            <DialogContent>
            {config?.systems ? (
               <Stack spacing={2} sx={{ pt: '4px' }}>
               {config?.systems.length > 1 && (
                  <FormControl fullWidth>
                     <InputLabel id="select-label">System</InputLabel>
                     <Select
                        label="System"
                        value={systemID ?? ''}
                        onChange={handleChangeSystem}
                     >
                     {config?.systems.map((system, index) => (
                        <MenuItem
                           key={index}
                           value={system.id}
                        >
                           {system.label}
                        </MenuItem>
                     ))}
                     </Select>
                  </FormControl>
               )}
                  <TextField
                     value={login ?? ''}
                     label="Login"
                     InputLabelProps={{ shrink: true, }} // fix for Chrome autofill
                     onChange={handleChangeLogin}
                     onKeyDown={handleKeyDown}
                  />
                  <TextField
                     value={password ?? ''}
                     label="Password"
                     type="password"
                     autoComplete="current-password"
                     InputLabelProps={{ shrink: true, }} // fix for Chrome autofill
                     onChange={handleChangePassword}
                     onKeyDown={handleKeyDown}
                  />
               </Stack>
            ) : (
               <CircularProgress />
            )}
               <DialogActions>
                     <Button
                        variant="contained"
                        onClick={handleLogin}
                     >
                        Ð’Ð¾Ð¹Ñ‚Ð¸
                     </Button>
               </DialogActions>
            </DialogContent>
         </Dialog>
      )}
   </>
   );
}